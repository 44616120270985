<template>
  <div>
    <div
      class="ghost-tool-drop-zone"
      :style="ghostToolStyle"
    ></div>
    <slot
      :column-size="columnSize"
      :window-height="windowHeight"
      :window-width="windowWidth"
    ></slot>
    <div
      class="bottom-padding"
      :style="bottomPaddingStyle"
    ></div>
  </div>
</template>

<script>
// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, onMounted, onBeforeUnmount, ref } from 'vue'
import { useStore } from 'vuex'

import { colPadding, numColumns, rowPadding, rowSize, themeColors } from '@/common/shared.js'

import toolDrag from './toolDrag.js'

export default {
  props: {
    doCommand: {
      type: Function,
      required: true,
    },
    refreshToolPositions: {
      type: Function,
      required: true,
    },
    setIsDraggingToolId: {
      type: Function,
      required: true,
    },
    setToolHeightInPixels: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()

    const windowHeight = ref(0)
    const windowWidth = ref(0)

    const columnSize = computed(() => (windowWidth.value - 0.5 * colPadding) / numColumns)

    const onResize = () => {
      windowHeight.value = window.innerHeight
      windowWidth.value = window.innerWidth
    }

    const { onToolDragStart, onToolDrag, onToolDragEnd } = toolDrag.setup(columnSize, props)

    const sessionContainerHeight = computed(() => {
      let topRow = 0
      store.getters.tools.forEach((tool) => {
        if (tool.row + tool.height > topRow) { topRow = tool.row + tool.height }
      })
      return rowSize * topRow
    })

    const bottomPaddingStyle = computed(() => {
      return {
        top: `${sessionContainerHeight.value - rowPadding}px`,
      }
    })

    const currentThemeName = computed(() => store.getters.currentThemeName)

    const ghostToolStyle = computed(() => {
      return {
        backgroundColor: themeColors[currentThemeName.value].toolDragTargetColor,
        outline: `4px dashed ${themeColors[currentThemeName.value].toolDragTargetOutlineColor}`,
      }
    })

    onMounted(() => {
      windowHeight.value = window.innerHeight
      windowWidth.value = window.innerWidth
      window.addEventListener('resize', onResize)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', onResize)
    })

    return {
      bottomPaddingStyle,
      columnSize,
      ghostToolStyle,
      onToolDragStart,
      onToolDrag,
      onToolDragEnd,
      sessionContainerHeight,
      windowHeight,
      windowWidth,
    }
  },
}
</script>

<style lang="scss" scoped>
div {
  position: relative;
}

.ghost-tool-drop-zone {
  position: absolute;
  top: 100px;
  left: 100px;
  width: 300px;
  height: 300px;
  background-color: #00eaff;
  outline: 4px dashed #0f7de4;
  display: none;
  transition: all 0.15s linear;
}

.bottom-padding {
  height: 1rem; // rowPadding
  width: 100%;
  position: absolute;
}
</style>

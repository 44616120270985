// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'

import { colPadding, minToolColumns, minToolRows, maxToolRows, numColumns, rowPadding, rowSize, themeColors } from '@/common/shared.js'

import { compareToolPositions, copyToolPositions, rearrangeRemainingTools } from './rearrange.js'

export default {
  setup(columnSize, props) {
    const store = useStore()

    const currentThemeName = computed(() => store.getters.currentThemeName)

    const ghostToolStyle = computed(() => {
      const backgroundColor = themeColors[currentThemeName.value].toolDragTargetColor
      const outline = `4px dashed ${themeColors[currentThemeName.value].toolDragTargetOutlineColor}`
      return `background-color: ${backgroundColor}; outline: ${outline};`
    })

    const ghostToolDropZone = ref(null)
    const ghostToolProxy = { col: 0, row: 0, width: 0, height: 0, widthInPixels: 0, heightInPixels: 0 }
    let currentScrollY = 0
    let maxRow = 0
    let toolsToShuffle = null
    let undoTools = null

    const updateGhostToolDropZone = () => {
      const newLeft = ghostToolProxy.col * columnSize.value + colPadding
      const newTop = ghostToolProxy.row * rowSize
      const newStyle = `left: ${newLeft}px; top: ${newTop}px; width: ${ghostToolProxy.widthInPixels}px; height: ${ghostToolProxy.heightInPixels}px; display: block; ${ghostToolStyle.value}`
      ghostToolDropZone.value.style = newStyle
    }

    const onToolDragStart = ({ toolId }) => {
      props.setIsDraggingToolId(toolId)
      currentScrollY = window.scrollY
      ghostToolDropZone.value.style = `display: block; ${ghostToolStyle.value}`
      const toolIndex = store.getters.toolsMapIndex[toolId]
      const tool = store.getters.tools[toolIndex]
      ghostToolProxy.width = tool.width
      ghostToolProxy.height = tool.height
      ghostToolProxy.widthInPixels = columnSize.value * ghostToolProxy.width - 2 * colPadding
      ghostToolProxy.heightInPixels = rowSize * ghostToolProxy.height - rowPadding
      ghostToolProxy.col = tool.col
      ghostToolProxy.row = tool.row
      updateGhostToolDropZone()

      undoTools = store.getters.tools.map((obj) => { return { id: obj.id, col: obj.col, row: obj.row, width: obj.width, height: obj.height } })
      toolsToShuffle = store.getters.tools.filter((t) => t.id !== toolId)
      toolsToShuffle.sort(compareToolPositions)

      maxRow = 0
      toolsToShuffle.forEach((t) => {
        if (t.row + t.height > maxRow) { maxRow = t.row + t.height }
      })
    }

    const onToolDrag = ({ toolId, left, top, widthInPixels, heightInPixels }) => {
      props.setToolHeightInPixels(toolId, heightInPixels)

      const row = Math.min(maxRow, Math.max(0, Math.round(top / rowSize)))
      const col = Math.min(numColumns - ghostToolProxy.width, Math.max(0, Math.round((left - colPadding) / columnSize.value)))
      const width = Math.min(numColumns, Math.max(minToolColumns, Math.round((widthInPixels + 2 * colPadding) / columnSize.value)))
      const height = Math.min(maxToolRows, Math.max(minToolRows, Math.round(heightInPixels / rowSize)))

      if (col === ghostToolProxy.col && row === ghostToolProxy.row &&
        width === ghostToolProxy.width && height === ghostToolProxy.height) { return }

      ghostToolProxy.col = col
      ghostToolProxy.row = row
      ghostToolProxy.width = width
      ghostToolProxy.height = height
      ghostToolProxy.widthInPixels = columnSize.value * ghostToolProxy.width - 2 * colPadding
      ghostToolProxy.heightInPixels = rowSize * ghostToolProxy.height - rowPadding
      updateGhostToolDropZone()

      const { tools: newPositions } = rearrangeRemainingTools(toolsToShuffle, ghostToolProxy)
      copyToolPositions(newPositions)

      maxRow = 0
      newPositions.forEach((t) => {
        if (t.row + t.height > maxRow) { maxRow = t.row + t.height }
      })
    }

    const onToolDragEnd = ({ toolId, dropEffect }) => {
      props.setIsDraggingToolId('')
      ghostToolDropZone.value.style = `display: none; ${ghostToolStyle.value}`
      if (dropEffect === 'none') {  // operation canceled.
        copyToolPositions(toolsToShuffle)  // restore original positions.
        props.refreshToolPositions()
        return
      }

      const toolIndex = store.getters.toolsMapIndex[toolId]
      const tool = store.getters.tools[toolIndex]
      if (tool.col === ghostToolProxy.col && tool.row === ghostToolProxy.row &&
        tool.width === ghostToolProxy.width && tool.height === ghostToolProxy.height) {
        return
      }

      const { tools: newPositions } = rearrangeRemainingTools(toolsToShuffle, ghostToolProxy)
      const copyUndoTools = undoTools.filter(() => true)
      props.doCommand({
        do: () => {
          copyToolPositions(newPositions)
          const col = ghostToolProxy.col
          const row = ghostToolProxy.row
          const width = ghostToolProxy.width
          const height = ghostToolProxy.height
          store.dispatch('updateToolPlacement', { id: toolId, col, row, width, height })
          props.refreshToolPositions()
        },
        undo: () => {
          copyToolPositions(copyUndoTools)
          props.refreshToolPositions()
          window.scrollTo({
            top: currentScrollY,
            left: 0,
            behavior: 'smooth',
          })
        },
      })
    }

    onMounted(() => {
      ghostToolDropZone.value = document.querySelector('.ghost-tool-drop-zone')
    })

    return {
      onToolDragStart,
      onToolDrag,
      onToolDragEnd,
    }
  },
}

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 448"
    :fill="modalIconColor"
  >
    <title>{{ tr('Expand tool to full screen') }}</title>
    <path d="M448 24v95.005c0 21.382-25.851 32.09-40.971 16.971l-27.704-27.704-107.242 107.243c-4.686 4.686-12.284 4.686-16.971 0l-22.627-22.627c-4.686-4.686-4.686-12.284 0-16.971L339.728 68.674 312.024 40.97C296.905 25.851 307.613 0 328.995 0H424c13.255 0 24 10.745 24 24zM175.917 232.485 68.674 339.728 40.97 312.024C25.851 296.905 0 307.613 0 328.995V424c0 13.255 10.745 24 24 24h95.005c21.382 0 32.09-25.851 16.971-40.971l-27.704-27.704 107.243-107.243c4.686-4.686 4.686-12.284 0-16.971l-22.627-22.627c-4.687-4.685-12.285-4.685-16.971.001z" />
  </svg>
</template>

<script>
// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, inject } from 'vue'
import { useStore } from 'vuex'

import { themeColors } from '@/common/shared.js'

export default {
  setup() {
    const store = useStore()

    const modalIconColor = computed(() => {
      return themeColors[store.getters.currentThemeName].toolIconColor
    })

    const tr = inject('tr')

    return {
      modalIconColor,
      tr,
    }
  },
}
</script>
